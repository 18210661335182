import React from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import GallerySlide from "../components/GallerySlide/GallerySlide"
import SectionHeadingH2 from "../components/SectionHeadingH2/SectionHeadingH2"
import photoCalidades from "../images/promociones/Jardin del Hidalgo/Calidades/calidades.jpg"
import photoSituacionPlano from "../images/promociones/Jardin del Hidalgo/Situacion/situacion-plano.jpg"
import photoSituacionMapa from "../images/promociones/Jardin del Hidalgo/Situacion/situacion-mapa.jpg"

const JardinHidalgoPage = () => {
  const images = useStaticQuery(graphql`
    query {
      photos1Ay2AJardinHidalgo: allFile(
        filter: {
          relativeDirectory: {
            eq: "promociones/Jardin del Hidalgo/1º A y 2º A"
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
      photosZonasComunesJardinHidalgo: allFile(
        filter: {
          relativeDirectory: {
            eq: "promociones/Jardin del Hidalgo/Zonas comunes"
          }
        }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
    }
  `)

  const photos1Ay2A = images.photos1Ay2AJardinHidalgo.nodes.map(node => {
    return {
      image: node.childImageSharp.fluid,
      imageThumbnail: `${node.childImageSharp.fluid.src}`,
      imageModal: `${node.childImageSharp.fluid.src}`,
      id: node.childImageSharp.id,
    }
  })

  const photosZonasComunes = images.photosZonasComunesJardinHidalgo.nodes.map(
    node => {
      return {
        image: node.childImageSharp.fluid,
        imageThumbnail: `${node.childImageSharp.fluid.src}`,
        imageModal: `${node.childImageSharp.fluid.src}`,
        id: node.childImageSharp.id,
      }
    }
  )

  return (
    <Layout>
      <div className="container">
        <SectionHeadingH2 sectionTitle="Jardín del Hidalgo" />
        <div className="text-component max-width-sm margin-x-auto">
          <p>
            Enclavado bajo la atenta mirada de la escultura al Minero y del AVE,
            tradición y modernidad se unen en este Exclusivo Residencial, que
            tiene la originalidad como seña de identidad.
          </p>
          <p>
            ¿Le gustaría aprovechar todas las ventajas de vivir en un chalet
            pero sin tener que renunciar a abandonar el casco urbano?. Damos
            cumplida respuesta a este interrogante construyendo un innovador
            proyecto arquitectónico con viviendas en urbanización privada
            cerrada, Piscina y zonas recreativas.
          </p>
          <p>
            Disfrute de una planta más en su vivienda con nuestros magníficos
            Dúplex con jardín privativo, con grandes áticos. Y todo ello con la
            calidad que sólo VIPROMAN le puede dar. A buen seguro no encontrará
            nada similar.
          </p>
        </div>
        <div className="grid gap-xl margin-y-xl">
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              1º A y 2º A
            </p>
            <GallerySlide
              gallerySlideListItems={photos1Ay2A}
              idLightbox="photos1Ay2A"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Zonas comunes
            </p>
            <GallerySlide
              gallerySlideListItems={photosZonasComunes}
              idLightbox="photosZonasComunes"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Calidades
            </p>
            <img src={photoCalidades} alt="" />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Ubicación
            </p>
            <img src={photoSituacionPlano} alt="" />
            <img src={photoSituacionMapa} alt="" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default JardinHidalgoPage
